import { merge } from "theme-ui"
import { DefaultTheme, ab } from "gatsby-theme-gba"

const gucColor = "#94C953"

const theme = merge(DefaultTheme.theme, {
  colors: {
    primary: gucColor,
    modes: {
      group: {
        primary: gucColor,
      },
      guc: {
        text: "#000",
        navigationBackground: "transparent",
      },
    },
    navigation: {
      background: "#fff",
    },
  },
  styles: {
    Main: {
      pr: "0 !important",
    },
    footer: {
      bg: "#122813",
      color: "#fff !important",
      "> div": {
        maxWidth: "1250px",
        margin: "auto",
        ":nth-child(2)": {
          display: "none",
        },
        ":nth-child(3)": {
          display: "none",
        },
        ":last-child": {
          a: {
            display: "block",
          },
        },
      },
      a: {
        color: "#fff",
      },
    },
  },
  spacingContainer: {
    my: ab({ _: 3, md: 3 }),
    px: ab({ _: 2, md: 3 }),
  },
  logo: {
    container: {
      position: "static",
      my: "20px",
      ml: 0,
    },
  },
  guc: {
    navigation: {
      display: "none",
    },
    mainNavigationItem: {
      color: "#000 !important",
      fontSize: 2,
      fontWeight: "bold",
    },
    pageTeasers: {
      gridColumnGap: 0,
      maxWidth: "1250px",
      display: ab({ _: "block", md: "grid" }),
      margin: "auto",
      pb: "48px",
    },
    pageTeaser: {
      mx: "10px",
      ".text": {
        textAlign: "left",
      },

      ".popup-text": {
        background: gucColor,
      },
    },
    alternativeBackground: {
      pt: "48px",
      ".page-teaser": {
        bg: "#122813",
        h2: {
          p: 0,
          m: 0,
        },
      },
    },
    jobsTable: {
      "> div": {
        px: ab({ _: "16px", md: "0" }),
        maxWidth: "1250px",
        m: "auto",
      },
    },
    slide: {
      color: "#000",

      img: {
        position: "static !important",
      },
      ".slide-heading": {
        fontSize: ab({ _: "18px", md: "24px" }),
        lineHeight: ab({ _: "26px ", md: "36px" }),
        pt: ab({ _: "5px ", md: "0px" }),
        textAlign: "left",
      },
      ".slide-text-left": {
        left: ab({ _: "0px ", md: "32px", lg: "90px" }),
        right: "auto",
        top: ab({ _: "auto ", md: "100px" }),
      },
      ".slide-text-right": {
        top: ab({ _: "80px ", md: "100px" }),
        left: ab({ _: "auto" }),
        right: ab({ _: "0px ", md: "32px", lg: "190px" }),
      },
      ".slide-text": {
        position: "absolute",
        maxWidth: "100%",
        width: ab({ _: " 100%", md: "60%", lg: "40%" }),
        bottom: ab({ _: " -30px", md: "auto" }),

        h6: {
          fontSize: ab({ _: "24px ", md: "34px" }),
          textTransform: "capitalize",
          letterSpacing: 0,
          textAlign: "left",
          lineHeight: ab({ _: "34px ", md: "48px" }),
          "@media (min-width:1200px)": {
            fontSize: "48px",
            lineHeight: "70px",
          },
        },
        p: {
          textAlign: "left",
        },
        ".text-reverse": {
          maxWidth: "100%",
          ".slide-heading": {
            fontSize: ab({ _: "18px ", md: "24px" }),
            lineHeight: ab({ _: "30px", md: " 36px" }),
            fontWeight: ab({ _: "bold ", md: "normal" }),
            fontStyle: "italic",
          },
        },
      },
    },

    slider: {
      svg: {
        fill: "#000 !important",
      },
      li: {
        borderColor: "#000",
      },
      "li.active": {
        bg: "#000 !important",
      },
      "div[role='button']": {
        display: ab({ _: "none", lg: "block" }),
      },
    },
    fullWidthSlide: {
      h2: {
        p: 0,
      },
      h6: {
        display: " none",
      },
      overflow: "visible",
      ".fullwidth-slide--text-container": {
        bg: gucColor,
        left: ab({ _: "0px ", md: "32px", lg: "90px" }),
      },
    },

    form: {
      margin: "auto",
      maxWidth: "1250px",
      pb: "48px",
      pr: ab({ lg: "0px" }),
      ul: {
        m: 0,
        p: 0,
      },
      li: {
        listStyle: "none",
      },
      ".formField": {
        bg: "#fff",
      },
      ".formField-textarea": {
        bg: "#fff",
      },
      ".mark": {
        backgroundColor: "#fff",
      },
    },
    successMessage: {
      ml: ab({ lg: "80px" }),
    },

    footer: {
      maxWidth: "1250px",
      p: 8,
      copyright: {
        display: "none",
      },
      info: {
        h3: {
          mt: 0,
        },
        ul: {
          listStyle: "none",
          pl: 0,
        },
        strong: {
          color: "primary",
        },
      },
    },
  },
  header: {
    navigation: {
      display: "none",
    },
  },
  oneColumn: {
    maxWidth: "1250px",
    margin: "auto",
    pt: 3,
    "h1,h2": {
      m: 0,
      p: "0 !important",
    },
    strong: {
      color: "primary",
    },
  },
})

export default theme
