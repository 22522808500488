/** @jsx jsx */
import { jsx, keyframes } from "theme-ui"
import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"

import { Link, ab, CloseIcon } from "gatsby-theme-gba"

const Header = ({ navigation }) => {
  const [open, setOpen] = useState(false)
  const toggleOverlay = () => setOpen(!open)
  const [submenuOpen, setSubmenuOpen] = useState({})
  const [isDesktop, setIsDesktop] = useState(false)

  const toggleSubmenu = (id) =>
    setSubmenuOpen((prev) => ({
      [id]: !prev[id],
    }))

  const nav = navigation["de"]["children"]

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 824)
    }

    window.addEventListener("resize", handleResize)
    handleResize()
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const listItems = nav.map((item) => (
    <li
      key={item._uid}
      sx={{
        position: "relative",
        "ul:hover": {
          display: "block",
          height: "auto",
        },

        ".open": {
          display: "block",
          maxHeight: "500px",
          transition: "max-height 0.25s ease-in",
          height: "auto",
          top: ab({ _: "0", md: "30px" }),
        },
        ".closed": {
          maxHeight: "0px",
        },
      }}
    >
      <Link
        to={item.url.id || item.url.url}
        language={"de"}
        onClick={(e) => {
          if (item.children && item.children.length > 0) {
            e.preventDefault()
            toggleSubmenu(item._uid)
          } else {
            toggleOverlay()
          }
        }}
        onMouseEnter={(e) => {
          if (isDesktop && item.children && item.children.length > 0) {
            toggleSubmenu(item._uid)
          }
        }}
        sx={{
          position: "relative",
        }}
      >
        {item.displayName}
      </Link>
      {item.children && item.children.length > 0 && (
        <ul
          className={submenuOpen[item._uid] ? "open" : "closed"}
          sx={{
            position: ab({ _: "relative", md: "absolute" }),
            top: "100%",
            left: 0,
            bg: "#fff",
            listStyle: "none",
            p: 0,
            m: 0,
            boxShadow: ab({ _: "", md: "0 4px 8px rgba(0,0,0,0.1)" }),
            zIndex: 1000,
            width: ab({ _: "100%", md: "300px" }),
            transition: "all 0.4s ease-in-out",
            maxHeight: ab({ _: "0px", md: "auto" }),
            height: "0px",
            overflow: "hidden",
            ul: {},
            "> li": {
              m: 0,
            },
            "> li > a": {
              display: "block",
              p: 2,
              textDecoration: "none",
              color: "#000",
              fontSize: "14px",

              "&:hover": {
                bg: "#f0f0f0",
              },
            },
          }}
        >
          {item.children.map(
            (child) =>
              child.displayName && (
                <li key={child._uid}>
                  <Link to={child.url.id || child.url.url} language={"de"}>
                    {" "}
                    {child.displayName}
                  </Link>
                </li>
              )
          )}
        </ul>
      )}
    </li>
  ))

  return (
    <header
      sx={{
        display: ab({ md: "flex" }),
        justifyContent: "space-between",
        maxWidth: "max",
        margin: "auto",
      }}
    >
      <div
        className="logo"
        sx={{
          display: ab({ _: "flex", md: "block" }),
          justifyContent: "space-between",
          mt: ab({ md: "10px" }),
          pl: ab({ md: "20px" }),
          ml: ab({ _: "0px ", md: "32px", lg: "90px" }),
        }}
      >
        <a href="/" title="GUC">
          <StaticImage
            src="../../assets/logos/guc-logo.png"
            style={{ width: "75%", maxWidth: "450px" }}
          />
        </a>
        <div
          className="navigation-opener"
          role="button"
          sx={{
            display: ab({ _: "flex", md: "none" }),
            alignItems: "center",
            width: "30px",
            height: "30px",
            right: "30px",
            position: "relative",
          }}
          onClick={() => toggleOverlay()}
        >
          <StaticImage src="../../assets/menu.png" alt="open menu" />
        </div>
      </div>

      <nav
        sx={{
          display: ab({ _: open ? "flex" : "none", md: "block" }),
          justifyContent: "center",
          alignItems: "center",
          position: ab({ _: "fixed", md: "static" }),
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          bg: "#fff",
          flexGrow: "1",
        }}
      >
        <div
          sx={{
            display: ab({ md: "none" }),
            width: "35px",
            height: "35px",
            position: "absolute",
            top: "15px",
            right: "45px",
          }}
          role="button"
          onClick={() => toggleOverlay()}
        >
          <CloseIcon />
        </div>
        <ul
          sx={{
            display: ab({ md: "flex" }),
            listStyle: "none",
            pl: 0,
            justifyContent: "space-evenly",

            li: {
              my: ab({ _: 4, md: 1 }),
              px: 3,
            },
            a: {
              fontSize: ab({ _: 4, md: "inherit" }),
              color: "#000",
              textDecoration: "none",
              fontWeight: "bold",
            },
          }}
        >
          {listItems}
        </ul>
      </nav>
    </header>
  )
}

export default Header
