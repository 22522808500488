module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-gba/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.guc-umwelt.de","siteId":"019f6838-d7ed-4e52-bd02-6746956f7267","branch":"master","context":"production"},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
